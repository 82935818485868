import { CellContentType } from './../../../../shared/components/table/models/cellContent.model';
import { TableHeaders } from './../../../../shared/components/table/models/tableHeaders.model';

export const ConfigurationDivisionsTableHeaders: TableHeaders[] = [
  {
    key: 'zip',
    label: 'ZIP CODE',
    content: {
      maxLength: 5,
      type: CellContentType.Text
    }
  },
  {
    key: 'state',
    label: 'STATE',
    content: {
      maxLength: 2,
      type: CellContentType.Text
    }
  },
  {
    key: 'name',
    label: 'DIV',
    content: {
      maxLength: 10,
      type: CellContentType.Text
    }
  },
  {
    key: 'abbr',
    label: 'ABBR',
    content: {
      maxLength: 3,
      type: CellContentType.Text
    }
  },
  {
    key: 'prefix',
    label: 'WO RULE',
    content: {
      maxLength: 1,
      type: CellContentType.Text
    }
  },
  {
    key: 'email',
    label: 'EMAIL',
    content: {
      type: CellContentType.Text
    }
  },
  {
    key: 'phone',
    label: 'PHONE',
    content: {
      type: CellContentType.Text
    }
  },
  {
    key: 'save',
    label: '',
    content: {
      type: CellContentType.Button,
      label: 'Save'
    },
    disabledCondition: ({ ...fields }) => {
      delete fields._rowId;
      delete fields.timeZone;
      delete fields.active;
      return !Object.values(fields).every(item => item);
    }
  },
  {
    key: 'active',
    label: ' ',
    content: {
      type: CellContentType.Switcher
    }
  }
];

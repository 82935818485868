



































import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';
import { ConfigurationDivisionsTableHeaders } from './model/ConfigurationDivisions.model';
import { AdminConfService } from '@/shared/services/admin-conf/admin-conf.service';
import { BaseConfigClass } from './BaseConfig.class';
import { ConfigType } from './model/Config.model';

@Component({
  components: { TmsTable }
})
export default class ConfigurationDivisions extends Vue {
  columns: TableHeaders[] = ConfigurationDivisionsTableHeaders;
  categories = null;
  rows = [];
  loading = true;
  adminService = AdminConfService;
  config = new BaseConfigClass(ConfigType.DIVISION);

  created() {
    this.config.getData();
  }

  save({ key, data }) {
    if (key == 'save') {
      this.config.onAction({ key: 'save', data });
    }
  }
}

import { CellContentType } from '../../../../shared/components/table/models/cellContent.model';
import { TableHeaders } from '../../../../shared/components/table/models/tableHeaders.model';

export const ConfigurationTypeTableHeaders: TableHeaders[] = [
  {
    key: 'name',
    label: 'TYPE',
    content: {
      maxLength: 20,
      type: CellContentType.Text
    }
  },
  {
    key: 'abbr',
    label: 'ABBR',
    content: {
      maxLength: 10,
      type: CellContentType.Text
    }
    // disabledCondition: ({ newItem }) => !newItem
  },
  {
    key: 'save',
    label: '',
    disabledCondition: ({ name, abbr }) => !name?.length || !abbr?.length,
    content: {
      type: CellContentType.Button,
      label: 'Save'
    }
  },
  {
    key: 'active',
    label: ' ',
    content: {
      type: CellContentType.Switcher
    }
  }
];

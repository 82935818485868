



































import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';
import { ConfigurationTypeTableHeaders } from './model/ConfigurationType.model';
import { ConfigType } from '@/pages/Admin/Configuration/model/Config.model';
import { BaseConfigClass } from '@/pages/Admin/Configuration/BaseConfig.class';
import { ConfigModule } from '@/store';

@Component({
  components: { TmsTable }
})
export default class ConfigurationType extends Vue {
  columns: TableHeaders[] = ConfigurationTypeTableHeaders;
  config = new BaseConfigClass(ConfigType.TYPE);

  created() {
    this.config.getData();
    ConfigModule.getConfigType();
  }
}

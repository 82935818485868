











































import Loading from '@/shared/components/Loading.vue';
import { ConfigService } from '@/shared/services/config.service';
import { ToastHelper } from '@/utils/toast.util';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { Loading }
})
export default class GpsSetting extends Vue {
  gpsinterval = 10;
  intervalIn = 'sec';
  intervalByOptions = [{ value: 'sec', text: 'sec' }];

  async created() {
    try {
      ConfigService.getGpsConfig().then(data => {
        this.gpsinterval = data.gpsDataPushFrequency;
      });
    } catch (err) {
      ToastHelper.show(`Admin - GPS Setting`, err.message, 5000, 'danger');
    }
  }

  async updateGpsInterval() {
    try {
      await ConfigService.updateGpsConfig({
        gpsDataPushFrequency: this.gpsinterval
      });
      ToastHelper.show(
        `Admin - GPS Setting`,
        'Data saved successfully',
        5000,
        'success'
      );
    } catch (err) {
      ToastHelper.show(`Admin - GPS Setting`, err.message, 5000, 'danger');
    }
  }
}
